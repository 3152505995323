
































import PaymentModel from '@/models/Payment.model';
import paymentsRepository from '@/repository/modules/payments.repository';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ListRowPayment from '../Payments/list-row-payment.vue';
@Component({
  components: {
    ListRowPayment,
  },
})
export default class ListPaymentsCustomer extends Vue {
  @Prop({ required: true, type: String })
  user_id!: string;
  page = 1;
  limit = 10;
  total_pages = 1;
  total_results = 0;
  loading = true;

  listPayments: PaymentModel[] = [];

  async mounted() {
    this.loadPayments();
  }

  async loadPayments() {
    this.loading = true;
    const { payments, total_results, total_pages } = await paymentsRepository.getListPayments({
      query: {
        user_id: this.user_id,
      },
      page: this.page,
      limit: this.limit,
    });
    this.listPayments = payments;
    this.total_results = total_results ?? 0;
    this.total_pages = total_pages;
    this.loading = false;
  }
}
