




































import { Component, Prop, Vue } from 'vue-property-decorator';

import requestsRepository from '@/repository/modules/requests.repository';
import { RequestModel } from '@/models/Request.model';
import TableRowRequest from '@/components/Requests/table-row-request.vue';

@Component({
  components: {
    TableRowRequest,
  },
})
export default class ListRequestCustomer extends Vue {
  @Prop({ required: true, type: String })
  user_id!: string;
  page = 1;
  limit = 10;
  total_pages = 1;
  total_results = 0;
  loading = true;
  listRequests: RequestModel[] = [];

  async created() {
    await this.changePage();
  }

  async changePage() {
    this.loading = true;
    const { requests, total_results, total_pages } = await requestsRepository.getListRequests({
      query: {
        user_id: this.user_id,
      },
      page: this.page,
      limit: this.limit,
    });
    this.listRequests = requests;
    this.total_results = total_results ?? 0;
    this.total_pages = total_pages;
    this.loading = false;
  }
}
