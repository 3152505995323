




































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomerModel, { CustomerModelDTO } from '@/models/Customer.model';
import { setterDate } from '@/plugins/utils';
import CustomerProfileBillingModel, { CustomerProfileBillingModelDTO } from '@/models/CustomerProfileBilling.model';

import CustomerRepository from '@/repository/modules/customers.repository';

@Component
export default class DetailsCustomer extends Vue {
  @Prop({ required: true, type: CustomerModel, default: () => new CustomerModel(new CustomerModelDTO()) })
  customer!: CustomerModel;

  @Prop({
    required: true,
    type: CustomerProfileBillingModel,
    default: () => new CustomerProfileBillingModel(new CustomerProfileBillingModelDTO()),
  })
  profileBilling!: CustomerProfileBillingModel;

  showEditorProfileBilling = false;
  isLoading = false;

  form = {
    company_vat: '',
    company_name: '',
    company_address: '',
    company_city: '',
    company_country: '',
  };

  openDialogFormProfileBilling() {
    this.setValueFormProfileBilling();
    this.showEditorProfileBilling = true;
  }

  setValueFormProfileBilling() {
    this.form.company_vat = this.profileBilling.company_vat;
    this.form.company_name = this.profileBilling.company_name;
    this.form.company_address = this.profileBilling.company_address;
    this.form.company_city = this.profileBilling.company_city;
    this.form.company_country = this.profileBilling.company_country;
  }

  async updateProfileBilling() {
    this.isLoading = true;
    CustomerRepository.updateProfileBilling(this.customer._id, {
      company_name: this.form.company_name,
      company_vat: this.form.company_vat,
      company_address: this.form.company_address,
      company_city: this.form.company_city,
      company_country: this.form.company_country,
    })
      .then((result) => {
        console.log(result);
        this.isLoading = false;
        this.showEditorProfileBilling = false;
        this.$emit('RELOAD_CUSTOMER');
      })
      .catch((e) => {
        this.isLoading = false;
        console.log(e);
      });
  }

  setDate = (dater: Date) => setterDate(dater);
  setEmptyField = (field: string) => (!field || field == '' ? '--' : field);
}
