





















import DetailsCustomer from '@/components/CustomerProfile/DetailsCustomer.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomersRepository from '@/repository/modules/customers.repository';
import CustomerModel, { CustomerModelDTO } from '@/models/Customer.model';
import CustomerProfileBillingModel, { CustomerProfileBillingModelDTO } from '@/models/CustomerProfileBilling.model';
import ListRequestCustomer from '@/components/CustomerProfile/ListRequestsCustomer.vue';
import ListPaymentsCustomer from '@/components/CustomerProfile/list-payments-customer.vue';
@Component({
  components: {
    ListRequestCustomer,
    ListPaymentsCustomer,
    DetailsCustomer,
  },
  props: {
    customer_id: {
      type: String,
      required: true,
    },
  },
})
export default class CustomerProfile extends Vue {
  @Prop({ required: true, type: String })
  customer_id!: string;
  customer = new CustomerModel(new CustomerModelDTO());
  profileBilling = new CustomerProfileBillingModel(new CustomerProfileBillingModelDTO());
  loading = true;
  tab = 0;

  async mounted(): Promise<void> {
    //await this.getCustomer();
    await this.loadAllData();
  }

  async loadAllData() {
    this.loading = true;
    Promise.all([this.getCustomer()]).finally(() => (this.loading = false));
  }

  async getCustomer() {
    const { customer, profileBilling } = await CustomersRepository.GetCustomerData(this.customer_id);
    this.customer = customer;
    this.profileBilling = profileBilling;
    // this.profilePayments = profilePayments;
  }
}
